import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import Seo from "../components/seo";
import Layout from "../containers/layout";
import { Helmet } from 'react-helmet';
import { toPlainText } from "../lib/helpers";

import LibraryList from "../components/library-list";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query LibraryTypeQuery($skip: Int!, $limit: Int!, $id: String!) {
    libraryLanding: sanityLibraryLanding {
      title
      themePurple
    }

    currentType: sanityLibraryType(id: { eq: $id }) {
      id
      title
      slug {
        current
      }
      _rawDescription
      seo {
        title
        description
        thumbnail {
          asset {
            _id
          }
          alt
        }
      }
    }

    library: allSanityLibrary(
      sort: { fields: [orderRank], order: ASC }
      filter: {types: {elemMatch: {libraryType: {id: {eq: $id}}}}}
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          thumbnail {
            ...SanityImage
            alt
          }
          types {
            _key
            libraryType {
              id
              title
              slug {
                current
              }
            }
          }
        }
      }
    }

    libraryAll: allSanityLibrary(
      sort: { fields: [orderRank], order: ASC }
    ) {
      edges {
        node {
          types {
            libraryType {
              id
            }
          }
        }
      }
    }

    libraryTypes: allSanityLibraryType(
      sort: { fields: [orderRank], order: ASC }
    ) {
      edges {
        node {
          id
          title
          slug {
            current
          }
        }
      }
    }
  }
`;

const LibraryTypeTemplate = (props) => {
  const { data, pageContext, errors } = props;
  const { currentPage, numPages } = pageContext;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const libraryLanding = (data || {}).libraryLanding;
  const currentType = (data || {}).currentType;

  const library = (data || {}).library.edges;
  const libraryAll = (data || {}).libraryAll.edges;

  const libraryTypes = (data || {}).libraryTypes.edges.filter(type => {
    const libraryInType = libraryAll.filter(post => (
      post.node.types.map(({libraryType}) => libraryType.id).includes(type.node.id)
    ));
    return libraryInType.length > 0;
  });

  const title = currentType.seo?.title || currentType.title;
  const description = currentType.seo?.description || toPlainText(currentType._rawDescription);
  const image = currentType.seo?.thumbnail;
  const autoSiteTitle = !currentType.seo?.title;

  return (
    <Layout>
      <Seo
        title={title}
        description={description}
        image={image}
        autoSiteTitle={autoSiteTitle}
      />

      <Helmet>
        <html className={libraryLanding.themePurple ? "theme-purple" : undefined} lang="en" />
      </Helmet>

      {library && (<LibraryList library={library} types={libraryTypes} landingTitle={libraryLanding.title} currentType={currentType} currentPage={currentPage} numPages={numPages} />)}
    </Layout>
  );
};

export default LibraryTypeTemplate;
